import * as React from "react"
import { Helmet } from "react-helmet";

import { Navigation, Content, Banner } from "../components";
import "../styles/main.scss";
import bar from "../images/bar.png";

import flag from "../images/icons/flag.svg";
import people from "../images/icons/people.svg";
import map from "../images/icons/map.svg";

// --------------------------------------------------------------------------------
// Banner
// --------------------------------------------------------------------------------
function PageBanner(): JSX.Element {
    const title = <>Lead your people agenda strategy with insight</>;

    const subtitle = <>
        <p>Drive your objectives with data-led thinking.<br />
            Inform your decisions and plans.<br />
            Report your successes and guide your teams.</p>
    </>;

    // const sparkle = <img className="w-100 h-100 border ps-2 py-2 bg-white" src={bar} width="417px" height="288px" alt="" aria-label="" />;
    const sparkle = <div className="text-md-end">
        <img src={bar} alt="Sample bar graph" className="bg-white border mw-100 xmh-100" />
    </div>;

    return <Banner
        title={title}
        subtitle={subtitle}
        action={{ to: "/contact-us/", caption: "Request a demo" }}
        sparkle={sparkle}
    />;
}


// --------------------------------------------------------------------------------
// Introduction
// --------------------------------------------------------------------------------
function PageIntro(): JSX.Element {
    const intro = <>
        <h2>Use the tools you've already invested in</h2>
        <p>The Qebal People Analytics Platform provides the foundation of a modern HR reporting strategy.</p>
        <p>The platform deals with the complexities of managing data and calculating your key HR metrics.</p>
        <p>The built&#8209;in reports can be exported and extended in tools such as Excel, Power BI and Tableau.</p>
    </>;

    return <div className="row row-cols-1 row-cols-sm-1 pb-4 gy-4">
        <div className="col text-sm-center px-4 ">
            {intro}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Body
// --------------------------------------------------------------------------------
function PageBody(): JSX.Element {
    const body1 = <>
        <h2>Workforce reports and dashboards</h2>
        <p>Building workforce planning capabilities using existing data helps focus attention on headcount, cost and damage limitation decision making.</p>
        <p>Bringing together your historical HR, financial and other company data in one place will allow your HR team to analyse people related data and make future workforce predictions in a safe, HR controlled environment.</p>
    </>;
    const body2 = <>
        <h2>Plan your workforce initiatives</h2>
        <p>Combining different data sets from across your organisation into one HR focused software platform means you can take control of how people data is analysed, interpreted and communicated.</p>
        <p>Promoting HR as the “go to” department for everything workforce planning related means you can realize your workforce strategy vision. And your HR teams will be empowered to take ownership of HR data analysis and stakeholder management, elevating HR as true business partners.</p>
    </>;

    return <div className="row row-cols-1 row-cols-sm-2 pb-4 gy-4">
        <div className="col px-4">
            {body1}
        </div>
        <div className="col px-4">
            {body2}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Features
// --------------------------------------------------------------------------------
function PageFeatures(): JSX.Element {
    const block1 = <>
        <div className="px-0 py-4">
            <img src={people} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />

            <h2>Query historical data</h2>
            <ul>
                <li>Conduct trend analysis</li>
                <li>Identify patterns in behaviour</li>
                <li>Assess employee spend v ROI</li>
                <li>Highlight issues and successes</li>
                <li>Conduct data audits and breaches</li>
            </ul>
        </div>
    </>;

    const block2 =
        <div className="px-0 py-4">
            <img src={flag} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />
            <h2>Assess current state</h2>
            <ul>
                <li>Measure employee engagement </li>
                <li>Analyse spans and layers of control</li>
                <li>Target employee relations to mitigate risk</li>
                <li>Evaluate recruitment, retention and attrition</li>
                <li>Plot capability, capacity and performance</li>
            </ul>
        </div>;

    const block3 = <>
        <div className="px-0 py-4">
            <img src={map} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />

            <h2>Predict future models</h2>
            <ul>
                <li>Anticipate workforce costs</li>
                <li>Forecast staffing requirements </li>
                <li>Plot staff changes and movements </li>
                <li>Estimate workforce costs</li>
                <li>Plan top talent and succession </li>
                <li>Performance management</li>
            </ul>
        </div>
    </>;

    return <div className="row row-cols-1 row-cols-sm-3">
        <div className="col qbl-block-1 px-4">
            {block1}
        </div>
        <div className="col qbl-block-2 px-4">
            {block2}
        </div>
        <div className="col qbl-block-1 px-4">
            {block3}
        </div>
    </div>;
}


const WorkforcePlanning = () =>
    <>
        <Helmet title="Qebal Workforce Planning" htmlAttributes={{ lang: 'en' }} />
        <Navigation />
        <Content>
            <div className="container-lg">
                <PageBanner />
                <PageIntro />
                <PageBody />
                <PageFeatures />
            </div>
        </Content>
    </>;

export default WorkforcePlanning;